import Constants from 'Constants'
import Common from '@/assets/js/common.js'

const QuotationMixins = {
	methods: {
		getAllQuotation: async function(start, end) {
			const url = Constants.DEVIS_BASE_URL + "?licence_key="+Constants.USER_LICENCE_KEY+"&start="+start+"&end="+end
			const result = await this.$request.request_get_api("QuotationMixins::getAllQuotation", url)
			if(result) return result.retour
			return null
		},

		getQuotationByTiers: async function(tiers_id, start, end) {
			const url = this.constructRoute(Constants.DEVIS_TIERS_URL, {tiers_id}) + "?licence_key="+Constants.USER_LICENCE_KEY+"&start="+start+"&end="+end
			const result = await this.$request.request_get_api("QuotationMixins::getQuotationByTiers", url)
			if(result) return result.retour
			return null
		},

		createQuotation: async function(params) {
			const url = Constants.DEVIS_BASE_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("QuotationMixins::createQuotation", url, params, false)
			.catch(error => {
				console.error("QuotationMixins::createQuotation => ERROR", error)
				if(error.response.data.message != ""){
					this.failureToast(error.response.data.message)
				}
				else{
					this.failureToast("toast.info_save_failed")
				}
				return null
			})
			
			return result
		},

		updateQuotation: async function(quotation_id, params) {
			const url = this.constructRoute(Constants.DEVIS_BY_ID_URL, {quotation_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("QuotationMixins::updateQuotation", url, params, false)
			.catch(error => {
				console.error("QuotationMixins::updateQuotation => ERROR", error)
				if(error.response.data.message != ""){
					this.failureToast(error.response.data.message)
				}
				else{
					this.failureToast("toast.info_save_failed")
				}
				return null
			})
			
			return result
		},

		getQuotationById: async function(quotation_id) {
			const url = this.constructRoute(Constants.DEVIS_BY_ID_URL, {quotation_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("QuotationMixins::getQuotationById", url)
			if(result) return result.retour
			return null
		},

		generateQuotationLink: async function(quotation_id) {
			const url = this.constructRoute(Constants.DEVIS_SHARE_URL, {quotation_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("QuotationMixins::generateQuotationLink", url)
			if(result) return result.retour
			return null
		},

		createDetailsQuotation: async function(params) {
			const url = Constants.DEVIS_DETAILS_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("QuotationMixins::createDetailsQuotation", url, params, false)
			.catch(error => {
				console.error("QuotationMixins::createDetailsQuotation => ERROR", error)
				if(error.response.data.message != ""){
					this.failureToast(error.response.data.message)
				}
				else{
					this.failureToast("toast.info_save_failed")
				}
				return null
			})
			
			return result
		},

		updateQuotationDetails: async function(details_id, params) {
			const url = this.constructRoute(Constants.DEVIS_DETAILS_BY_ID_URL, {details_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("QuotationMixins::updateQuotationDetails", url, params, false)
			.catch(error => {
				console.error("QuotationMixins::updateQuotationDetails => ERROR", error)
				if(error.response.data.message != ""){
					this.failureToast(error.response.data.message)
				}
				else{
					this.failureToast("toast.info_save_failed")
				}
				return null
			})
			
			return result
		},

		getDetailsByQuotation: async function(quotation_id) {
			const url = this.constructRoute(Constants.DEVIS_DETAILS_BY_QUOTATION_URL, {quotation_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("QuotationMixins::getDetailsByQuotation", url)
			if(result) return result.retour
			return null
		},

		saveQuotationDetailsOrder: async function(params) {
			const url = Constants.DEVIS_DETAILS_ORDER_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("QuotationMixins::saveQuotationDetailsOrder", url, {details:params}, false)
			.catch(error => {
				console.error("QuotationMixins::saveQuotationDetailsOrder => ERROR", error)
				if(error.response.data.message != ""){
					this.failureToast(error.response.data.message)
				}
				else{
					this.failureToast("toast.info_save_failed")
				}
				return null
			})
			
			return result
		},

		deleteQuotationDetails: async function(details_id) {
			const url = this.constructRoute(Constants.DEVIS_DETAILS_BY_ID_URL, {details_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_delete_api("QuotationMixins::deleteQuotationDetails", url)
			if(result) return result.retour
			return null
		},

		getQuotationDetailById: async function(details_id) {
			const url = this.constructRoute(Constants.DEVIS_DETAILS_BY_ID_URL, {details_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("QuotationMixins::getQuotationDetailById", url)
			if(result) return result.retour
			return null
		},

		getQuotationPdf: async function(quotation_id, quotation_num, base64 = false) {
			const url = this.constructRoute(Constants.DEVIS_PDF_URL, {quotation_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("QuotationMixins::getQuotationByTiers", url)
			if(result) {
				if(base64) {
					return result
				}
				Common.base64ToPdf(result, quotation_num + ".pdf")
			}
			return null
		},

		convertQuotationToBAF: async function(quotation_id) {
			const url = this.constructRoute(Constants.DEVIS_CONVERT_URL, {quotation_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("QuotationMixins::convertQuotationToBAF", url)
			if(result) return result.retour
			return null
		},

		sendMailQuotation: async function(quotation_id, model_type = "quotation_notification", model_id = null, commentaire = '', expeditor = null) {
			const url = this.constructRoute(Constants.DEVIS_SEND_URL, {quotation_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const params = {model_type, model_id, commentaire, expeditor}
			
			const result = await this.$request.request_post_api("QuotationMixins::sendMailQuotation", url, params, false)
			.catch(error => {
				console.error("QuotationMixins::sendMailQuotation => ERROR", error)
				return null
			})

			return result ? result.retour : null
		},

		sendMailDocumentQuotation: async function(quotation_id, document_id, model_type = "quotation_notification", model_id = null, commentaire = '', expeditor = null) {
			const url = this.constructRoute(Constants.DEVIS_SEND_DOCUMENT_URL, {quotation_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const params = {document_id, model_type, model_id, commentaire, expeditor}
			
			const result = await this.$request.request_post_api("QuotationMixins::sendMailDocumentQuotation", url, params, false)
			.catch(error => {
				console.error("QuotationMixins::sendMailDocumentQuotation => ERROR", error)
				return null
			})

			return result ? result.retour : null
		},

		duplicateQuotation: async function(quotation_id, tiers_id) {
			const url = this.constructRoute(Constants.DEVIS_DUPLICATE_URL, {quotation_id, tiers_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("QuotationMixins::duplicateQuotation", url)
			if(result) return result.retour
			return null
		},

		// DOCUMENT
		getDocumentByQuotation: async function(quotation_id) {
			const url = this.constructRoute(Constants.DEVIS_DOCUMENT_URL, {quotation_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("QuotationMixins::getDocumentByQuotation", url)
			if(result) return result.retour
			return null
		},

		addQuotationDocument: async function(quotation_id, document_label) {
			const url = this.constructRoute(Constants.DEVIS_DOCUMENT_URL, {quotation_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("QuotationMixins::getDocumentByQuotation", url, {document_label}, false)
			if(result) return result.retour
			return null
		},

		uploadDocumentQuotationFile: async function(quotation_id, document_id, document_file) {
			const url = this.constructRoute(Constants.DEVIS_DOCUMENT_FILE_URL, {quotation_id, document_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_post_file_api("QuotationMixins::uploadDocumentQuotationFile", url, [
				{ 
					name: 'document',
					content: document_file
				}])
				.catch(e => {
					console.error("QuotationMixins::uploadDocumentQuotationFile => ERROR", e)
					return null
				})
				.then(res => {
					return res.retour
				})
		},

		editQuotationDocument: async function(quotation_id, document_id, document_label) {
			const url = this.constructRoute(Constants.DEVIS_DOCUMENT_BY_ID_URL, {quotation_id, document_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("QuotationMixins::editQuotationDocument", url, {document_label}, false)
			if(result) return result.retour
			return null
		},

		deleteQuotationDocument: async function(quotation_id, document_id) {
			const url = this.constructRoute(Constants.DEVIS_DOCUMENT_BY_ID_URL, {quotation_id, document_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_delete_api("QuotationMixins::deleteQuotationDocument", url)
			if(result) return result.retour
			return null
		},

		sendQuotationSignature: async function(quotation_id) {
			const url = this.constructRoute(Constants.DEVIS_SEND_SIGNATURE_URL, {quotation_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("QuotationMixins::sendQuotationSignature", url, null, false)
			if(result) return result.retour
			return null
		},

		generateQuotationSignatureLink: async function(quotation_id) {
			const url = this.constructRoute(Constants.DEVIS_SEND_SIGNATURE_URL, {quotation_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("QuotationMixins::generateQuotationSignatureLink", url)
			if(result) return result.retour
			return null
		}
	}
}

export default QuotationMixins
